@import "src/styles/variables";
@import "src/styles/responsive";

.post {
  margin: 64px auto;
  display: flex;
  flex-direction: column;
  gap: 56px;
  min-height: 60vh;

  @include media(mobile) {
    gap: 40px;
  }

  .general {
    width: 800px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media(mobile) {
      gap: 12px;
      width: auto;
      margin: 0 20px;
    }

    .date {
      color: $primary;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;

      @include media(mobile) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .title {
      margin-bottom: 16px;
    }
  }

  .picture {
    display: flex;
    align-items: center;
    width: 800px;

    @media screen and (max-width: 1024px){
      width: auto;
    }

    img {
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      object-position: center;
      height: 100%;
    }
  }

  .article {
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 42px;
    }

    @include media(mobile) {
      width: auto;
      margin: 0 20px;

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .slider {

  }
}