@import './variables.scss';
@import './responsive.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'DBAdmanX';
  src: url('fonts/DBAdmanX.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  min-height: 100vh;
  background-size: cover;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
span,
input,
button,
label,
textarea {
  font-family: 'DBAdmanX', sans-serif;
  color: $text-main;
}

h1 {
  font-size: 44px;
  line-height: 52px;
  font-weight: 700;

  @media (max-width: $container--landscape - 1) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: $container--mobile - 1) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;

  @media (max-width: $container--landscape - 1) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (max-width: $container--mobile - 1) {
    font-size: 24px;
    line-height: 32px;
  }
}

h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  @media (max-width: $container--landscape - 1) {
    font-size: 32px;
    line-height: 48px;
  }

  @media (max-width: $container--mobile - 1) {
    font-size: 20px;
    line-height: 24px;
  }
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: $container--mobile - 1) {
    line-height: 20px;
  }
}
