@import "src/styles/variables";
@import "src/styles/responsive";

.slider {
  position: relative;

  .transparent_left, .transparent_right {
    position: absolute;
    width: calc((100vw - 800px - 2.1vw) / 2);
    height: 400px;
    background: rgba(255,255,255,0.5);
    top: 0;
    z-index: 5;

    @media screen and (max-width: $container--tablet){
      width: 40px;
    }

    @include media(mobile) {
      height: 200px;
    }
  }

  .transparent_left {
    border-radius: 0 8px 8px 0;
    left: 0;
  }

  .transparent_right {
    border-radius: 8px 0 0 8px;
    right: 0;
  }

  .carousel {

    .picture {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      width: 800px;
      padding: 0 20px;

      @include media(mobile) {
        height: 200px;
      }

      img {
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
        object-position: center;
        height: 100%;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 34px;

    .prevArrow {
      rotate: 90deg;
    }

    .nextArrow {
      rotate: -90deg;
    }

    .prevArrow, .nextArrow {
      border: none;
      background: none;
      cursor: pointer;

      svg {

        path {
          transition: ease all 0.3s;
          stroke: $grey;
        }
      }

      &:hover, &:active, &:focus {

        svg {

          path {
            stroke: $black;
          }
        }
      }
    }

    .count {
      font-size: 20px;
      line-height: 28px;
    }
  }
}